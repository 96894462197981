<template>
  <div>
    <v-card>
      <v-card-title>Arguments</v-card-title>
      <v-card-text>
        <div v-if="inputs.length == 0 || inputs.every(i => i.hide)">
          No more arguments to set for this step.
        </div>
        <v-form
          v-else
          ref="form"
          :disabled="isSubmitting"
          @submit.prevent
        >
          <v-row
            v-for="(input, i) in inputs"
            v-show="input.name!=='compound_identifier_name'"
            :key="`input_${i}`"
          >
            <v-col v-if="!input.hide" cols="3">
              <v-subheader>{{ input.name }}</v-subheader>
            </v-col>
            <v-col v-if="!input.hide" cols="9">
              <ModuleInput
                ref="children"
                :name="input.name"
                :info="input"
                :is-sg-model-field="module.type === 'StructureGeneration'"
                @changeParameter="onChange"
                @setFile="onSetFile"
              />
            </v-col>
          </v-row>
          <v-row v-if="module.has_linked_uncertainty">
            <v-col cols="3">
              <v-subheader>compute_uncertainty</v-subheader>
            </v-col>
            <v-col cols="9">
              <v-checkbox
                v-model="computeUncertainty"
                label="Compute Uncertainty"
                @change="onSelectComputeUncertainty"
              />
            </v-col>
          </v-row>
          <v-row v-if="module.rnn_volume">
            <v-col>
              <RnnVolumeSelection
                ref="rnnVolumeSelection"
                @select="onRnnVolumeSelect"
              />
            </v-col>
          </v-row>
          <v-row v-if="module.reward_config_file">
            <v-col>
              <div
                v-for="(info, name) in rewardSetting"
                :key="name"
              >
                <ModuleInput
                  ref="children"
                  :name="name"
                  :info="info"
                  :all-required="true"
                  @changeParameter="onChange"
                  @setFile="onSetFile"
                />
              </div>
            </v-col>
          </v-row>
          <v-dialog v-model="dialog" width="500">
            <MemoDialog
              :title="'You can write notes about this workflow'"
              :cancel-button-label="'cancel'"
              :submit-button-label="'next'"
              :placeholder="'With a higher priority for hA2A, hA1, and hERG compatibility.'"
              @cancel="dialog = false"
              @confirm="setMemo(...arguments); setPermission();"
            />
          </v-dialog>
          <v-dialog v-model="permissionDialog" width="500">
            <ExecutionVisibility
              :choices="visibilityChoices"
              :group-options="groupOptions"
              @cancel="permissionDialog = false"
              @confirm="submitForm"
            />
          </v-dialog>
        </v-form>
      </v-card-text>
      <v-divider class="mt-12" />
      <v-card-actions>
        <v-btn
          text
          :disabled="isSubmitting || inputs.length == 0 || inputs.every(i => i.hide)"
          @click="clearForm"
        >
          Clear form
        </v-btn>
        <v-spacer />
        <v-btn
          v-show="!firstStep"
          style="margin-right:auto"
          dense
          :disabled="isSubmitting"
          @click="stepBack"
        >
          {{ 'Previous' }}
        </v-btn>
        <v-btn
          :color="lastStep ? 'warning' : 'primary'"
          dense
          :disabled="isSubmitting"
          :loading="isSubmitting"
          @click="stepSubmit"
        >
          {{ lastStep ? 'Execute' : 'Next' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import MemoDialog from '@/components/MemoDialog';
import ModuleInput from '@/components/ModuleInput';
import consts from '@/store/consts';
import ExecutionVisibility from '@/components/ExecutionVisibility';
import RnnVolumeSelection from '@/components/RnnVolumeSelection';
import { showErrorDialog } from '@/mixins/utils';
import { Kekule } from 'kekule';

export default {
  name: 'ProcessStep',
  components: {
    MemoDialog,
    ModuleInput,
    ExecutionVisibility,
    RnnVolumeSelection
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    allParams: {
      type: Array,
      default: () => []
    },
    process: {
      type: Array,
      default: () => null
    },
    module: {
      type: Object,
      default: () => null
    },
    firstStep: {
      type: Boolean,
      default: false
    },
    lastStep: {
      type: Boolean,
      default: false
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      permissionDialog: false,
      visibilityChoices: [],
      groupOptions: [],
      inputs: [],
      parameters: {},
      files: {},
      commonInputs: [
        'compound_identifier_name',
        'compound_structure_name'
      ],
      memo: '',
      rewardSetting: { ...consts.RewardSetting },
      computeUncertainty: false
    };
  },
  watch: {
    allParams: {
      handler() {
        if (!this.firstStep && this.allParams.length > 0) {
          this.inputs = this.inputs.map(input => {
            if (this.commonInputs.includes(input.name)) {
              this.parameters[input.name] = this.allParams[0][input.name];
              input.hide = true;
            }
            return input;
          });
        }
      },
      deep: true
    }
  },
  mounted() {
    const self = this;
    const moduleConfig = JSON.parse(this.module.config);
    this.inputs = Object.entries(moduleConfig.input || {}).filter(([name, info]) => {
      return this.firstStep || (!this.firstStep && info.type !== 'file');
    }).map(([name, info]) => {
      return { name, ...info };
    });
    this.api.getWorkflowVisibility(function(choices) {
      self.visibilityChoices = choices;
    });
    this.groupOptions = this.$session.get('user').groups;
  },
  methods: {
    onChange(...args) {
      const [name, value] = args;
      this.parameters[name] = value;
    },
    onSetFile(...args) {
      const [name, file] = args;
      if (file) {
        this.files[name] = file;
      }
    },
    clearForm() {
      this.$refs.children.forEach(childForm => childForm.clearForm());
    },
    stepSubmit() {
      if (this.$refs.children.find(child => child.name === 'compounds_file') &&
      this.$refs.children.find(child => child.name === 'compounds_file').isDrawnCompound) {
        this.convertedCSV();
      }
      if (this.module.rnn_volume && !this.$refs.rnnVolumeSelection.validatedForm()) {
        showErrorDialog('Please select a RNN model.');
        return;
      }
      // This invokation of validation is important
      // Without the smiles will not change with the value of editor when
      // We use edit for input_smiles field for SG model
      const isValidForm = this.$refs.children
        .map(child => child.validatedForm())
        .flat(Infinity).every(valid => valid);

      if (!isValidForm) {
        showErrorDialog('Input is wrong');
        return;
      }

      if (this.lastStep) {
        this.dialog = true;
      } else {
        this.$emit('stepSubmit', this.index, this.parameters, '', this.files);
      }
    },
    setMemo(memo) {
      this.memo = memo;
      this.dialog = false;
    },
    setPermission() {
      if (this.visibilityChoices.length > 0) {
        this.permissionDialog = true;
      } else {
        showErrorDialog(
          'Something went wrong.',
          'Cannot get visibility options. Please contact an administrator.'
        );
      }
    },
    submitForm(visibility, group) {
      this.$emit(
        'stepSubmit',
        this.index,
        this.parameters,
        this.memo,
        this.files,
        visibility,
        group
      );
    },
    stepBack() {
      this.$emit('stepBack', this.index);
    },
    onRnnVolumeSelect(volume) {
      this.parameters.rnn_volume = volume;
    },
    onSelectComputeUncertainty() {
      this.parameters.compute_uncertainty = this.computeUncertainty;
    },
    convertedCSV() {
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById('kekule')
      );
      const smiles = Kekule.IO.saveFormatData(
        composer.getChemObj(),
        'smi'
      );
      const csvHeader = ['daiia_id', this.parameters.compound_structure_name];
      const csvData = ['1', smiles];
      const csvContent = csvHeader.join(',') + '\n' + csvData.join(',') + '\n';
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const csv = new File([blob], 'compound.csv');
      this.onSetFile('compounds_file', csv);
    }
  }
};
</script>
