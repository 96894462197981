<template>
  <div class="line">
    <div v-if="isObjectType()">
      <span class="label">{{ label }}:</span>
      <div class="indent border-box">
        <div
          v-for="(child_info, child_name) in info.parameters"
          :key="child_name"
        >
          <v-form @submit.prevent>
            <ModuleInput
              ref="children"
              :validate="false"
              :name="child_name"
              :info="child_info"
              :all-required="allRequired"
              :value="value ? value[child_name] : null"
              :reward-items="setRewards()"
              :is-reward-input-prop="isRewardInputData"
              :is-sg-model-field="isSgModelField"
              @changeParameter="onChangeChild"
              @setFile="onSetFile"
            />
          </v-form>
        </div>
      </div>
    </div>
    <div v-if="isTextType()">
      <v-form ref="form" @submit.prevent>
        <GroupedAutocomplete
          v-if="isRewardOriginInput()"
          v-model="textValue"
          :selected-value="textValue"
          :items="rewardItems"
          :label="label"
          group-name="category"
          item-value="origin"
          item-text="name"
          :rules="[...requiredRules]"
          validate-on-blur
          required
          @change="onOriginChange"
        />
        <div
          v-else-if="info.type != 'text'"
          class="text-field-container"
        >
          <v-text-field
            v-if="!isDrawnCompound"
            v-model="textValue"
            :rules="[...requiredRules]"
            validate-on-blur
            :label="label"
            v-bind="$attrs"
            :required="true"
            @change="onChange"
          />
          <CompoundEditor
            v-if="isDrawnCompound && isSgModelField && name === 'input_smiles'"
            :compound-allows-null="true"
            kekule-id="input-smiles-editor"
          />
          <v-btn
            v-if="isSgModelField && name === 'input_smiles'"
            color="primary"
            @click="changeInputFormat"
          >
            <span v-if="!isDrawnCompound">Draw in editor</span>
            <span v-if="isDrawnCompound">Use input field</span>
          </v-btn>
        </div>
        <v-textarea
          v-if="info.type == 'text'"
          v-model="textValue"
          :rules="[...requiredRules]"
          validate-on-blur
          :label="label"
          v-bind="$attrs"
          @change="onChange"
        />
      </v-form>
    </div>
    <div v-if="isFileType()">
      <v-row>
        <v-col v-if="!isDrawnCompound">
          <v-form ref="form" @submit.prevent>
            <v-file-input
              v-bind="$attrs"
              :label="label"
              :rules="[v => !!v || 'A file is required']"
              show-size
              @change="onChange"
            />
          </v-form>
        </v-col>
        <v-col v-if="name === 'compounds_file' || name === 'input_sdf'">
          <v-btn
            color="primary"
            @click="changeInputFormat"
          >
            <span v-if="!isDrawnCompound">Draw in editor</span>
            <span v-if="isDrawnCompound">Use a file</span>
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="isDrawnCompound">
        <CompoundEditor
          :compound-allows-null="true"
          :kekule-id="name === 'compounds_file' ? 'kekule' : 'kekule-profile-settings'"
        />
      </div>
    </div>
    <div v-if="isListType()">
      <!-- Validation not implemented -->
      <span class="label">
        {{ label }}:
        <v-btn
          color="primary"
          class="mx-2"
          elevation="3"
          fab
          x-small
          @click="addListItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          v-if="list.length > 0"
          color="error"
          elevation="3"
          fab
          x-small
          @click="removeListItem"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </span>
      <div class="indent">
        <v-form ref="form" @submit.prevent>
          <ModuleInputBlock
            v-for="(item, index) in list"
            ref="collection"
            :key="index"
            :element="info.element"
            :all-required="allRequired"
            :item="item"
            :index="index"
            :reward-items="setRewards()"
            :is-reward-input-prop="isRewardInputData"
            :is-sg-model-field="isSgModelField"
            @changeParameter="onChangeChild"
            @rewardChanged="removeOldRewardValue"
            @rewardScalingChanged="removeOldThresholdValues"
            @setFile="onSetFile"
          />
        </v-form>
      </div>
      <div v-if="isRewardInputData && list.length > 0">
        <span class="label">
          <v-btn
            color="primary"
            class="mx-2"
            elevation="3"
            fab
            x-small
            @click="addListItem"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="list.length > 0"
            color="error"
            elevation="3"
            fab
            x-small
            @click="removeListItem"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </span>
      </div>
    </div>
    <div v-if="isDictionaryType()">
      <span class="label">{{ label }}:</span>
      <div class="indent border-box">
        <div
          v-for="(child_info, child_name) in info.element"
          :key="child_name"
        >
          <v-form @submit.prevent>
            <ModuleInput
              ref="children"
              :name="child_name"
              :info="child_info"
              :all-required="allRequired"
              :value="value ? value[child_name] : null"
              :reward-items="setRewards()"
              :is-reward-input-prop="isRewardInputData"
              :is-sg-model-field="isSgModelField"
              @changeParameter="onChangeChild"
              @setFile="onSetFile"
            />
          </v-form>
        </div>
      </div>
    </div>
    <div v-if="isBooleanType()">
      <v-form ref="form" @submit.prevent>
        <v-checkbox
          v-model="bool"
          :label="label"
          :rules="[...requiredRules]"
          validate-on-blur
          @change="onChange"
        />
      </v-form>
    </div>
    <div v-if="isListFilesType()">
      <v-form ref="form" @submit.prevent>
        <v-file-input
          :value="files"
          multiple
          show-size
          v-bind="$attrs"
          :label="label"
          :rules="[
            v => v.length > 0 || 'At least one file is required',
            v => v.length <= 6 || 'The maximum number of files is 6'
          ]"
          validate-on-blur
          @change="onChange"
          @click:clear="onClickClearListOfFiles"
        >
          <template #selection="{ text, index }">
            <v-chip
              color="white"
              close
              @click:close="removeFilesItem(index)"
            >
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </v-form>
    </div>
    <div v-if="isSelectType()">
      <v-form ref="form" @submit.prevent>
        <v-autocomplete
          v-model="textValue"
          :items="info.options ?? []"
          :label="label"
          item-value="value"
          item-text="text"
          :rules="[...requiredRules]"
          validate-on-blur
          v-bind="$attrs"
          @change="onChange"
        />
      </v-form>
    </div>
    <div v-if="info.type == 'label'">
      <h3>{{ info.text }}</h3>
    </div>
  </div>
</template>

<script>
import ModuleInputBlock from '../components/ModuleInputBlock';
import GroupedAutocomplete from './GroupedAutocomplete.vue';
import CompoundEditor from '../components/design/Project/CompoundEditor.vue';
import { Kekule } from 'kekule';
import {
  showErrorDialog, renameFile,
  getSmilesListForEachAsteriskFromEditor
} from '@/mixins/utils';

export default {
  name: 'ModuleInput',
  components: {
    ModuleInputBlock,
    GroupedAutocomplete,
    CompoundEditor
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => null
    },
    allRequired: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, Object, Array, Boolean],
      default: () => null
    },
    rewardItems: {
      type: Array,
      default: () => []
    },
    // Keep the "Prop" suffix for this variable
    isRewardInputProp: {
      type: Boolean,
      default: false
    },
    isSgModelField: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      label: '',
      textValue: '',
      file: null,
      object: Object(),
      list: [],
      dictionary: {},
      bool: false,
      showOption: false,
      files: [],
      rewards: [],
      isDrawnCompound: false,
      // To not confuse with isRewardInputProp
      isRewardInputData: false,
      pendingReward: null
    };
  },
  computed: {
    requiredRules: function() {
      if (this.allRequired) {
        return [v => !(typeof (v) !== 'boolean' && !v) || 'The field is required.'];
      } else {
        return [];
      }
    }
  },
  watch: {
    info: {
      handler(newVal, oldVal) {
        this.initialize();
      },
      deep: true
    },
    value: {
      handler(newVal, oldVal) {
        this.initialize();
      },
      deep: true
    },
    isRewardInputProp: {
      handler(newVal, oldVal) {
        this.isRewardInputData = newVal;
      }
    },
    rewardItems: {
      handler(newVal, oldVal) {
        if (this.pendingReward !== null) {
          this.rewardSelected(this.pendingReward);
        }
      }
    }
  },
  mounted() {
    const self = this;
    this.rewards = this.rewardItems;
    if (this.name === 'reward_config') {
      this.isRewardInputData = true;
      this.api.getRewards(function(list) {
        list.sort((a, b) => {
          const categoryOrder = ['Potency', 'Toxicity', 'ADME', 'Property', 'Other'];

          const categoryComparison = categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category);
          if (categoryComparison === 0) {
            return a.name.localeCompare(b.name);
          }

          return categoryComparison;
        });
        self.rewards = list;
      });
    }
    this.label = '';
    this.initialize();
  },
  methods: {
    clearForm() {
      this.files = [];
      if (this.isObjectType() || this.isDictionaryType()) {
        this.$refs.children.forEach(childForm => childForm.clearForm());
      } else if (this.isListType()) {
        // Currently do not reset form content in list type
        // TODO: Need to add the ability to delete list type arguments
      } else if (!this.isListFilesType()) {
        this.$refs.form.reset();
      }
    },
    isTextType() {
      // list-values is comma-separated values (= one line of text)
      return [
        'integer', 'float', 'string', 'smiles', 'text',
        'list-strings', 'list-numbers'
      ].includes(this.info.type);
    },
    isFileType() {
      return ['file'].includes(this.info.type);
    },
    isObjectType() {
      return ['yaml', 'json'].includes(this.info.type);
    },
    isListType() {
      return ['list'].includes(this.info.type);
    },
    isDictionaryType() {
      return ['dictionary'].includes(this.info.type);
    },
    isBooleanType() {
      return ['boolean'].includes(this.info.type);
    },
    isListFilesType() {
      return ['list-files'].includes(this.info.type);
    },
    isSelectType() {
      return ['select'].includes(this.info.type);
    },
    isRewardOriginInput() {
      return this.name === 'origin' && this.isRewardInputProp;
    },
    isRewardScalingInput() {
      return this.name === 'scaling' && this.isRewardInputProp;
    },
    setRewards() {
      return this.rewards.length > 0 ? this.rewards : this.rewardItems;
    },
    validatedForm() {
      if (this.isDrawnCompound) {
        if (this.isValidCompoundStructure()) {
          // If validation pass the compound from editor to parameters
          // this only work with SG model and input_smiles field
          this.readSmilesFromEditor();
          return true;
        } else {
          return false;
        }
      } else if (this.isObjectType() || this.isDictionaryType()) {
        return this.$refs.children.map(child => child.validatedForm());
      } else if (this.isListType() && this.$refs.collection) {
        return this.$refs.collection.map(child => child.validatedForm());
      } else {
        return this.$refs.form.validate();
      }
    },
    onChange(e) {
      if (this.isTextType()) {
        this.$emit('changeParameter', this.name, this.textValue);
      } else if (this.isFileType()) {
        this.file = Array.isArray(e) ? e[0] : e;
        this.file = renameFile(this.file);
        this.$emit('setFile', this.name, this.file);
      } else if (this.isObjectType()) {
        this.$emit('changeParameter', this.name, this.object);
      } else if (this.isListType()) {
        const value = this.list;
        this.$emit('changeParameter', this.name, value);
      } else if (this.isDictionaryType()) {
        this.$emit('changeParameter', this.name, this.dictionary);
      } else if (this.isBooleanType()) {
        this.$emit('changeParameter', this.name, this.bool);
      } else if (this.isListFilesType()) {
        this.files = [
          ...this.files,
          ...(e || []).filter(file =>
            this.files.every(f => f.name !== file.name))
        ];
        this.$emit('setFile', this.name, this.files.map(renameFile));
      } else if (this.isSelectType()) {
        if (this.isRewardScalingInput()) {
          this.$emit('scalingSelect', this.textValue);
        }
        this.$emit('changeParameter', this.name, this.textValue);
      }
    },
    onChangeChild(name, value, index = null) {
      // In case of Module input is a member of list type argument,
      // Index helps us to keep track of which element at which position,
      // this module input belongs
      if (
        this.info.parameters &&
                this.info.parameters[name].type === 'file'
      ) {
        this.$emit('setFile', name, value);
        return;
      }
      if (this.isObjectType()) {
        this.object[name] = value;
      } else if (this.isListType()) {
        this.list[index][name] = value;
      } else if (this.isDictionaryType()) {
        this.dictionary[name] = value;
      }
      this.onChange(null);
    },
    onSetFile(name, value, index = null) {
      // In case of Module input is a member of list type argument,
      // Index helps us to keep track of which element at which position,
      // this module input belongs
      const attributeName = `${this.name}${index !== null ? '___' + index : ''}${'___' + name}`;
      this.$emit('setFile', attributeName, value);
    },
    addListItem() {
      this.list.push({});
    },
    removeListItem() {
      this.list.pop();
    },
    toggleOption() {
      this.showOption = !this.showOption;
    },
    initialize() {
      if ('description' in this.info) {
        this.label = this.info.description.trim();
      }
      if (!this.info.is_reward) {
        this.label = `[${this.name}] ${this.label}`.trim();
      }
      if (this.label.length === 0) {
        this.label = `[${this.name}]`;
      }
      if (this.isBooleanType()) {
        if (this.bool === this.value) this.onChange();
        this.bool = (this.value !== null ? this.value : this.info.default.toLowerCase() === 'true');
      } else if (this.isTextType()) {
        if (this.textValue === this.value) this.onChange();
        this.textValue = this.value !== null ? this.value : this.info.default;
        if (['list-strings', 'list-numbers'].includes(this.info.type) &&
          Array.isArray(this.textValue)) {
          this.textValue = this.textValue.join();
        }
        if (this.isRewardOriginInput()) {
          this.rewardSelected(this.textValue);
        }
      } else if (this.isObjectType()) {
      // Keep until completely tested
      // for (const name in this.info.parameters) {
      //     this.object[name] = this.info.parameters[name].default;
      // }
      } else if (this.isListType()) {
        if (this.list === this.value) this.onChange();
        if (this.value !== null) {
          this.list = this.value;
        } else if (!this.info.initiallyEmpty) {
          /* `initiallyEmpty` filed will stop list item
              to have one element when initialize.
              Can add `initiallyEmpty` as boolean in list item info
          */
          const item = {};
          for (const name in this.info.element) {
            if (this.info.element[name].default) {
              item[name] = this.info.element[name].default;
            }
          }
          this.list.push(item);
        }
      } else if (this.isDictionaryType()) {
        // Keep until completely tested
        // if (this.dictionary === this.value) this.onChange();
        // for (const name in this.info.element) {
        //   if (this.value !== null && this.value[name] !== undefined) {
        //     this.dictionary[name] = this.value[name];
        //   } else {
        //     this.dictionary[name] = this.info.element[name].default;
        //   }
        // }
      } else if (this.isSelectType()) {
        const options = this.info.options.map(item => item.value);
        if (options.includes(this.textValue)) {
          this.onChange();
        } else if (options.includes(this.value)) {
          this.textValue = this.value;
        } else {
          this.textValue = this.info.default;
        }
      }
      if (!this.isFileType() || !this.isListFilesType()) {
        this.onChange();
      }
    },
    removeFilesItem(index) {
      this.files.splice(index, 1);
    },
    onClickClearListOfFiles() {
      this.files = [];
    },
    rewardSelected(reward) {
      if (this.rewardItems.length === 0) {
        this.pendingReward = reward;
      } else {
        const selectedReward = this.rewardItems
          .find(item => item.origin === reward);
        if (selectedReward) {
          this.$emit('rewardSelect', selectedReward.objective_server);
        }
      }
    },
    onOriginChange(value) {
      this.rewardSelected(value);
      this.onChange();
    },
    removeOldRewardValue(oldRewardInputs, index) {
      if (this.list.length > index) {
        for (const name of oldRewardInputs) {
          delete this.list[index][name];
        }
      }
    },
    removeOldThresholdValues(thresholdFields, index) {
      // When change scaling value in reward setting,
      // remove old good_value value
      delete this.list[index].good_value;
      // remove old threshold values
      if (this.list.length > index) {
        if (thresholdFields) {
          for (const name in this.list[index].threshold) {
            if (!Object.keys(thresholdFields).includes(name)) {
              delete this.list[index].threshold[name];
            }
          }
        } else {
          delete this.list[index].threshold;
        }
      }
    },
    isValidCompoundStructure() {
      let editorElementId = 'kekule-profile-settings';
      if (this.name === 'compounds_file') {
        editorElementId = 'kekule';
      } else if (this.name === 'input_smiles') {
        editorElementId = 'input-smiles-editor';
      }
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById(editorElementId)
      );
      const mols = composer.exportObjs(Kekule.Molecule);
      if (mols.length < 1) {
        showErrorDialog('Please write a compound structure in the editor.');
        return false;
      }
      if (mols.length > 1) {
        showErrorDialog('Please write a single compound structure in the editor.');
        return false;
      }
      return true;
    },
    changeInputFormat() {
      this.isDrawnCompound = !this.isDrawnCompound;
      if (this.name === 'input_sdf') {
        this.$emit('resetState');
      }
    },
    readSmilesFromEditor() {
      if (this.isSgModelField && this.isDrawnCompound && this.name === 'input_smiles') {
        const composer = Kekule.Widget.getWidgetOnElem(
          document.getElementById('input-smiles-editor')
        );
        const smiles = Kekule.IO.saveFormatData(
          composer.getChemObj(),
          'smi'
        );
        const nbAsterisk = (smiles.match(/\*/g) || []).length;
        const processedCompounds = getSmilesListForEachAsteriskFromEditor(nbAsterisk, 'input-smiles-editor');
        this.$emit('changeParameter', this.name, processedCompounds);
      }
    }
  }
};
</script>

<style scoped>
.line {
    margin-top: 5px;
    margin-bottom: 5px;
}
.label {
    display: inline-block;
}
.border-box {
    padding-left: 10px;
    border-left: solid 1px;
}
.indent {
    margin-left: 50px;
}
input {
    border: solid 1px;
}
textarea {
    border: solid 1px;
    width: 500px;
}
.text-field-container {
  display: flex;
  align-items: center;
}
.text-field-container > * {
  margin-right: 10px;
}

</style>
