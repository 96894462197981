<template>
  <div>
    <v-dialog v-model="dialog" width="1200">
      <TargetHeatMap :info="currentGroup.info" />
    </v-dialog>
    <v-row no-gutters>
      <v-btn
        color="warning"
        class="ml-2"
        @click="$emit('createChild', selectedRow)"
      >
        結果から再実行
      </v-btn>
      <v-spacer />
      <div>
        数値フィルダーの使い方
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon v-on="on">mdi-help-circle</v-icon>
          </template>
          <span>{{ filterTips[0] }}<br>{{ filterTips[1] }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <v-radio-group v-model="selectedRow">
      <v-data-table
        :headers="headers"
        :items="compounds"
        :loading="isLoading"
        :options.sync="options"
        loading-text="ロード中..."
        :no-data-text="noDataText"
        :page-count="pageCount"
        :server-items-length="itemCount"
        multi-sort
        dense
        class="elevation-1"
        hide-default-footer
        :items-per-page="itemsPerPage"
        @pagination="onPaginationChange"
      >
        <template #top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :disable-items-per-page="noResultsToDisplay"
            :options="options"
            :items-per-page-options="itemsPerPageOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            :show-first-last-page="true"
            @update:options="updateOptions"
          />
        </template>
        <template #body.prepend>
          <tr class="filter-row">
            <td v-for="header in headers" :key="`filter_${header.value}`">
              <v-text-field
                v-if="header.value in numericFilters"
                ref="numericFilter"
                v-model="numericFilters[header.value]"
                outlined
                dense
                label="フィルター"
                :rules="[matchConditionRule]"
                @keydown.enter="filterData"
              />
            </td>
          </tr>
        </template>
        <template #item="{ item, index }">
          <tr>
            <template v-for="header in headers">
              <td
                v-if="header.value == 'no'"
                :key="`${header.value}_${index}`"
              >
                {{ index + 1 }}
              </td>
              <td
                v-else-if="header.value == 'structure'"
                :key="`${header.value}_${index}`"
              >
                <!--
                Can not use :value = "item.is_ref"
                badge is still displayed if item.is_ref is undefined
                which is the case for old PD execution results
              -->
                <v-badge
                  content="参照化合物"
                  :value="item.is_ref === true"
                  color="rgb(153,0,153)"
                  bordered
                  bottom
                  overlap
                  offset-x="25"
                  offset-y="20"
                >
                  <span v-if="showStructure" v-html="item.structure" />
                </v-badge>
              </td>
              <td
                v-else-if="headerClasses[header.text] == 'clickable group-cell'"
                :key="`${header.value}_${index}`"
                :class="'clickable group-cell'"
                @click="openDialog(item, header)"
              >
                <strong class="group-inactive">
                  {{ profileInactiveCount(item, header.text) }}
                </strong>
                / {{ targetCounts[header.text] }}
              </td>
              <td
                v-else-if="header.value == 'selection'"
                :key="`${header.value}_${index}`"
              >
                <v-radio :value="item" />
              </td>
              <td
                v-else
                :key="`${header.value}_${index}`"
                :class="activity(item, header)"
              >
                {{ formatValue(item[header.value]) }}
              </td>
            </template>
          </tr>
        </template>
      </v-data-table>
    </v-radio-group>
  </div>
</template>

<script>
import { formatNumber, activityCssClass } from '@/mixins/utils';
import TargetHeatMap from '@/components/design/TargetHeatMap';
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';

export default {
  name: 'ResultList',
  components: {
    'TargetHeatMap': TargetHeatMap
  },
  props: {
    compounds: {
      type: Array,
      default: () => []
    },
    profile: {
      type: Array,
      default: () => []
    },
    showStructure: {
      type: Boolean,
      required: true
    },
    idField: {
      type: String,
      default: 'ID'
    },
    filters: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    noDataText: {
      type: String,
      default: 'データがありません'
    },
    preventOptionsUpdate: {
      type: Boolean,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    itemCount: {
      type: Number,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    },
    noResultsToDisplay: {
      type: Boolean,
      required: true
    },
    filterTips: {
      type: Array,
      default: () => [
        '数値フィルターを適用するには、条件を入力してEnterキーを押してください。',
        '条件には、>x、<x、>=x、<=x、=x のような式を使用してください。x は検索値を表します。'
      ]
    }
  },
  data() {
    return {
      headers: [],
      dialog: false,
      currentGroup: {},
      headerClasses: {},
      targetCounts: {},
      hiddenColumnNames: ['structure', 'mol', 'smiles'],
      itemsPerPage:
        Number(localStorage.getItem('pd-res-ipp')) ||
        ITEMS_PER_PAGE_OPTIONS[0],
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS.slice(0, 3),
      options: {},
      selectedRow: null,
      numericFilters: {}
    };
  },
  computed: {
  },
  watch: {
    compounds: {
      handler() {
        if (this.headers.length > 0) {
          return;
        }
        this.headers = [];
        if (this.compounds.length > 0) {
          this.headers.push(
            { text: '', value: 'selection', width: '.1%', sortable: false },
            { text: '#', value: 'no', width: '1%', sortable: false },
            { text: '構造', value: 'structure', width: '1%', sortable: false },
            { text: this.idField, value: this.idField, width: '1%' },
            { text: 'reward', value: 'reward', width: '1%', sortable: true }
          );
          for (const filter of this.filters) {
            this.headers.push({ text: filter, value: filter, width: '1%' });
          }
          const compoundHeaders = Object.keys(this.compounds[0]);
          for (const category of ['Potency', 'Property', 'Toxicity', 'ADME']) {
            // Profiles read from sdf shouldn't be contain in output
            const targets = this.profile.filter(p => p.category === category);

            // If both predictionDisplayName and propertyName are null,
            // the item was from SDF and is not displayed
            const names = targets.map(
              t => t.predictionDisplayName
            ).filter(t => t != null);
            for (const name of names) {
              this.appendColumn(name);
              if (compoundHeaders.includes(`${name}_uncertainty`)) {
                this.appendColumn(`${name}_uncertainty`);
              }
            }
          }
          this.updateNumericFilters(this.headers);
        }
      },
      deep: true
    },
    headers: {
      handler() {
        for (const header of this.headers) {
          const profileInfo = this.profileInfo(header.text);
          if (profileInfo?.predictionType === 'MultipleFeaturePrediction') {
            this.headerClasses[header.text] = 'clickable group-cell';
            this.targetCounts[header.text] = this.multipleTargetCount(header);
          } else {
            this.headerClasses[header.text] = '';
            this.targetCounts[header.text] = 1;
          }
        }
      }
    },
    options: {
      handler() {
        // onPaginationChange では、ソート情報の変更をキャッチできない
        if (!this.preventOptionsUpdate) {
          this.updateData();
        }
      },
      deep: true
    },
    selectedFilters: {
      handler() {
        this.$emit('setPreventOptionsUpdate', true);
        this.options.page = 1;
        this.updateData();
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    appendColumn(name) {
      if (this.hiddenColumnNames.includes(name)) {
        return;
      }
      if (name.startsWith('CHEMTS_')) {
        return;
      }
      this.headers.push(
        { text: name, value: name, width: '1%' }
      );
    },
    profileInfo(target) {
      return this.profile.find(p => p.initialName === target ||
                              p.predictionDisplayName === target);
    },
    activity(item, header) {
      if (this.filters.includes(header.text)) {
        return item[header.text] === 0 ? 'profile-active' : 'profile-inactive';
      }
      const target = header.text;
      const value = item[target];
      const profileInfo = this.profileInfo(target);
      if (profileInfo) {
        return activityCssClass(value, profileInfo);
      } else {
        return '';
      }
    },
    formatValue(value) {
      return formatNumber(value);
    },
    generateTargetGroupData(item, header) {
      const newTargets = {};
      const profileInfo = this.profileInfo(header);
      const targets = Object.keys(item).map((name) => ({
        name: name.includes(header) ? name.replace(header + '_', '') : false,
        activity: activityCssClass(item[name], profileInfo),
        predicted: item[name]
      }));
      newTargets.info = { targets: targets.filter(t => t.name !== false) };
      return newTargets;
    },
    profileInactiveCount(item, header) {
      const activities = ['profile-inactive'];
      const targets = this.generateTargetGroupData(item, header).info;
      const count = activities.reduce((acc, n) => {
        acc[n] = 0;
        return acc;
      }, {});
      targets.targets.forEach((e) => {
        if (e.activity in count) {
          count[e.activity] += 1;
        }
      });
      return count[activities];
    },
    multipleTargetCount(header) {
      if (this.compounds.length > 0) {
        return Object.keys(this.compounds[0]).reduce(
          (count, i) => i.includes(header.text) ? count + 1 : count, 0);
      }
      return 0;
    },
    openDialog(item, header) {
      this.currentGroup = this.generateTargetGroupData(item, header.text);
      this.dialog = true;
    },
    updateData() {
      const filterNames = this.selectedFilters.map((filter) =>
        filter.predicted_value_name
      );
      this.$emit(
        'updateData',
        this.options,
        filterNames,
        JSON.stringify(this.numericFilters)
      );
    },
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('pd-res-ipp', pagination.itemsPerPage);
      }
    },
    isNumber(name) {
      return this.compounds.every((compound) => !isNaN(compound[name]));
    },
    matchConditionRule(value) {
      if (value === '') {
        return true;
      }
      const pattern = /^(>=|<=|>|<|=)\s*\d+(\.\d+)?$/;
      if (pattern.test(value)) {
        return true;
      } else {
        return '条件は「>, >=, <, <=, =」のいずれかと数字を指定してください。';
      }
    },
    filterData() {
      const isValid = this.$refs.numericFilter.every(field => field.validate());
      if (isValid) {
        this.$emit(
          'updateData',
          this.options,
          this.selectedFilters,
          JSON.stringify(this.numericFilters)
        );
      }
    },
    updateNumericFilters(headers) {
      for (const header of headers) {
        if (!(header.value in this.numericFilters) &&
            this.isNumber(header.value)) {
          this.numericFilters[header.value] = '';
        }
      }
    }
  }
};
</script>

<style scoped>
:deep(.v-data-table .v-data-table__wrapper) {
  overflow: unset;
}
:deep(.v-data-footer) {
    position: sticky;
    top: 110px;
    z-index:20;
    background-color: white;
}
:deep(.v-data-table-header th) {
    position: sticky;
    top: 151px;
    z-index:20;
    background-color: white;
}
:deep(.v-data-table .filter-row) {
  position: sticky;
  top: 183px;
  background-color: white;
  z-index: 20;
}

:deep(.v-data-table .filter-row:hover) {
  background-color: inherit !important;
}

</style>
